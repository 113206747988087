import { Injectable } from '@angular/core';
import { BehaviorSubject, iif, of } from 'rxjs';
import { AccountsClient, EFieldGroupType, UserAccountViewModel, UserInformation } from '@volt/api';
import * as _ from 'lodash';
import { AuthService } from '../../auth';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class AccountDropdownService {
  private readonly _accountTypes$ = new BehaviorSubject<EFieldGroupType[] | undefined>(undefined);
  private readonly _shouldUseDataWarehouse = new BehaviorSubject(false);

  constructor(private readonly _authService: AuthService, private readonly accountClient: AccountsClient) {}

  get accounts$() {
    return this._authService.authUser$.pipe(
      switchMap(user =>
        iif(() => user.canViewMultipleAccounts(), this.getAccountsFromApi$(), this.getAccountsFromCurrentUser$(user)),
      ),
      switchMap(accounts => this.filterAccounts$(accounts)),
    );
  }

  get shouldDisableDropdown$() {
    return this._authService.authUser$.pipe(map(user => !user.canViewMultipleAccounts()));
  }

  private filterAccounts$(accounts: UserAccountViewModel[]) {
    return this._accountTypes$.asObservable().pipe(
      map(accountTypes => {
        let accountsCopy = accounts.slice();
        accountsCopy =
          accountTypes?.length > 0 ? this.getFilteredAccountTypes(accountsCopy, accountTypes) : accountsCopy;
        return accountsCopy;
      }),
    );
  }

  private getFilteredAccountTypes(accounts: UserAccountViewModel[], accountTypes: EFieldGroupType[]) {
    return accounts.filter(item =>
      accountTypes.some(
        (v: string) => item.fieldGroupType != null && v.toLowerCase() === item.fieldGroupType.toLowerCase(),
      ),
    );
  }

  private getAccountsFromCurrentUser$(user: UserInformation) {
    return of([
      {
        ...user.account,
        aname: user.account.name,
        accountId: user.account.accountId,
        fieldGroupType: user.account.fieldGroupType,
      },
    ] as UserAccountViewModel[]);
  }

  private getAccountsFromApi$() {
    return this._shouldUseDataWarehouse.pipe(
      switchMap(useDataWarehouse => {
        return iif(
          () => useDataWarehouse,
          this.accountClient.getAccountsDropdown(),
          this.accountClient.getAllAccounts(),
        );
      }),
    );
  }

  set accountTypes(accountTypes: EFieldGroupType[] | undefined) {
    if (!_.isEqual(accountTypes, this._accountTypes$.value)) {
      this._accountTypes$.next(accountTypes);
    }
  }

  set useDataWarehouse(useDataWarehouse: boolean) {
    this._shouldUseDataWarehouse.next(useDataWarehouse);
  }
}
