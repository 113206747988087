import * as moment from 'moment';

export class ServiceDeploymentSearchParameters {
  constructor() {
    this.accountIds = [];
    this.accountName = '';
    this.name = '';
    this.note = '';
    this.referenceNumber = '';
    this.visitOverview = '';
    this.status = '';
    this.serviceDeploymentId = '';
    this.startDate = null;
    this.endDate = null;
  }


  public accountIds: number[];
  public accountName: string;
  public name: string;
  public note: string;
  public referenceNumber: string;
  public serviceDeploymentId: string;
  public visitOverview: string;
  public status: string;
  public startDate: Date;
  public endDate: Date;
}
