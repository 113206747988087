import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DynamicDialogService } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';
import { FormcreationService } from '../../advanced-search/services/formcreation.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AdvancedSearchComponent } from '../../advanced-search/components/advanced-search.component';
import { AllowedView } from '../../../../shared/allowed-view';
import { ServiceDeploymentSearchParameters } from 'src/app/servicedeployments-tasks-locations/models/servicedeployment-search-parameters';
import { BaseFilterForm } from '@volt/shared/components/form-fields/base-filter-form';
import { Translations } from '@volt/shared/services/translations.types';
import * as _ from 'lodash';
import { AuthService } from 'src/app/auth';
import { DebounceEventEmitter } from '@volt/shared/extensions/debounce-event-emitter';

@Component({
  selector: 'service-deployment-filter',
  templateUrl: 'service-deployment-filter.component.html',
  styleUrls: [],
})
export class ServiceDeploymentFilterComponent
  extends BaseFilterForm<ServiceDeploymentSearchParameters>
  implements OnInit {
  public allowedView = AllowedView;
  public colsHide = [
    'serviceDeploymentTaskCount',
    'serviceStart',
    'serviceEnd',
    'createdDate',
    'lastUpdated',
    'name',
    'serviceDeploymentTaskLocationCount',
    'estimate',
    'instructionUrls',
    'start',
    'end',
    'completionDate',
    'number',
    'updatedDate',
    'aname',
  ];

  @Input() public unfilteredList;
  @Input() public filters: ServiceDeploymentSearchParameters;
  @Input() translations: Translations;
  @Input() canCreateDeployments = false;

  @Output() export = new EventEmitter<boolean>();
  @Output() filterChanged = new DebounceEventEmitter<ServiceDeploymentSearchParameters>(400);
  @Output() createDeployment = new EventEmitter<null>();

  public advancedSearchFilters = [];
  public advancedSearchSelectedParams;
  public advancedSearchForm: UntypedFormGroup;

  constructor(
    private readonly _formCreationService: FormcreationService,
    private readonly _dynamicDialogService: DynamicDialogService,
    private readonly _authService: AuthService,
    private readonly _fb: UntypedFormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    this.initFormSharedValues(({ previous, current }) => {
      if (!_.isEqual(previous, current)) {
        this.validateAndEmitValues(previous, current);
      }
    });
  }

  initForm(): void {
    this.form = this._fb.group({
      accountIds: [this.filters.accountIds],
      name: [this.filters.name],
      startDate: [this.filters.startDate],
      endDate: [this.filters.endDate],
      note: [this.filters.note],
      referenceNumber: [this.filters.referenceNumber],
      visitOverview: [this.filters.visitOverview],
      status: [this.filters.status],
    });

    this.form.defaultValues = new ServiceDeploymentSearchParameters();
  }

  showAdvancedFilterDialog() {
    if (this.unfilteredList.length !== 0) {
      this.advancedSearchFilters = [];
      this.unfilteredList.forEach(i => {
        if (this.colsHide.indexOf(i.field) === -1) {
          this.advancedSearchFilters.push({ key: i.field, value: '', header: i.header });
        }
      });
    } else {
      this.advancedSearchFilters = [];
    }

    this.advancedSearchForm = this._formCreationService.toFormGroup(this.advancedSearchFilters);

    this._dynamicDialogService
      .open(AdvancedSearchComponent, {
        data: { form: this.advancedSearchForm, advancedSearchFilters: this.advancedSearchFilters },
        closeOnEscape: true,
      })
      .subscribe((submitted: boolean) => {
        if (submitted) {
          for (const field in this.advancedSearchForm.controls) {
            if (this.form.get(field).value !== this.advancedSearchForm.get(field).value)
              this.form.get(field).setValue(this.advancedSearchForm.get(field).value);
          }
        }
      });
  }

  public clearFilters() {
    this.form.reset();
  }

  public onExport() {
    this.export.emit(true);
  }

  private validateAndEmitValues(
    previousFilter: ServiceDeploymentSearchParameters,
    updatedFilter: ServiceDeploymentSearchParameters,
  ) {
    if (!updatedFilter.startDate || !updatedFilter.endDate) {
      this.filterChanged.emit(updatedFilter);
      return;
    }

    if (updatedFilter.startDate.getTime() > updatedFilter.endDate.getTime()) {
      if (updatedFilter.startDate !== previousFilter.startDate) {
        this.controls.endDate.setValue(updatedFilter.startDate);
      } else {
        this.controls.startDate.setValue(updatedFilter.endDate);
      }
    } else {
      this.filterChanged.emit(updatedFilter);
    }
  }
}
