import { Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { serviceDeploymentsReportSelector } from '../../../reducers/service-deployments-report.reducer';
import { TableDisplay } from '../../table-display/table-display.component';
import { ServiceDeploymentsService } from '../services/service-deployments.service';
import { ServiceDeploymentReportParams } from '../../../models/servicedeployment-report-parameters';
import { FileResponse, GenericTableReportOfServiceDeploymentViewModel, ServiceDeploymentViewModel } from '@volt/api';
import { LazyLoadEvent } from 'primeng/api';
import { Observable, SubscriptionLike as ISubscription } from 'rxjs';

import { ServicedeploymentReduxService } from '../../../services/servicedeployment.redux.service';
import { DataPassingService } from '../../../services/data-passing.service';
import { ServiceDeploymentSearchParameters } from '../../../models/servicedeployment-search-parameters';
import { CommonUtils } from '@volt/shared/utils/common.utils';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AddServicedeploymentReduxService } from '../../addservicedeployments/services/add-servicedeployment.redux.service';
import { AddServiceDeployment } from '../../../models/addServiceDeployment';
import { select } from '@angular-redux/store';
import { AddServiceDeploymentStateConstant } from '../../../actions/servicedeployment.constants';
import { PermissionsService, Privilege } from '@volt/shared/services/permissions.service';
import { PermissionNames } from '@volt/shared/services/permissionNames';

@Component({
  selector: 'service-deployment',
  templateUrl: 'servicedeployments.component.html',
  styleUrls: ['./servicedeployments.component.scss'],
})
export class ServicedeploymentsComponent extends TableDisplay implements OnInit, OnDestroy {
  public status;
  public currentServiceDeployment: AddServiceDeployment;
  public minDate: Date;
  public showEndDate: boolean;
  public serviceDeploymentId: string;
  public filters = new ServiceDeploymentSearchParameters();

  private dataSubscription: ISubscription;
  private routeSubscription: ISubscription;
  private serviceDeploymentsReportSubscription: ISubscription;

  @select([AddServiceDeploymentStateConstant.FilterStateName])
  private readonly addServiceDeploymentData: Observable<AddServiceDeployment>;

  @select(serviceDeploymentsReportSelector)
  private readonly serviceDeploymentsReport$: Observable<ServiceDeploymentReportParams>;

  canCreateDeployment = this.permissionsService.hasPermission(PermissionNames.SchedulesManage, Privilege.Create);
  canEdit = this.permissionsService.hasPermission(PermissionNames.SchedulesManage, Privilege.Update);

  constructor(
    private _serviceDeploymentService: ServiceDeploymentsService,
    private _router: Router,
    private _servicedeploymentReduxService: ServicedeploymentReduxService,
    private _addServiceDeploymentReduxService: AddServicedeploymentReduxService,
    private _dataPassingService: DataPassingService,
    private _route: ActivatedRoute,
    private readonly permissionsService: PermissionsService,
  ) {
    super(_serviceDeploymentService);
  }

  public refresh() {
    const servicedeploymentParams = new ServiceDeploymentReportParams();
    servicedeploymentParams.sortColumn = 'name';
    this._serviceDeploymentService.setFilter(servicedeploymentParams);
    this.pullData(servicedeploymentParams);
  }

  ngOnInit() {
    this.addServiceDeploymentData.subscribe(
      (data: AddServiceDeployment) => {
        this.currentServiceDeployment = data;
      },
      (err: any) => {
        console.error(err);
      },
    );

    this.routeSubscription = this._route.params.subscribe((params: Params) => {
      this.serviceDeploymentId = params['serviceDeploymentId'];
      this.searchbyId(this.serviceDeploymentId);
    });

    this._dataPassingService.advancedSearchParams.subscribe(servicedeploymentSearchParameters => {
      if (servicedeploymentSearchParameters) {
        const updateFilter = Object.assign(this.serviceDeploymentFilterParams, { servicedeploymentSearchParameters });
        this._serviceDeploymentService.setFilter(updateFilter);
      }
    });
  }

  pullData(servicedeployemntfilterParams: ServiceDeploymentReportParams) {
    this.loading = true;
    this.dataSubscription = this._serviceDeploymentService
      .getServiceDeployments(servicedeployemntfilterParams)
      .subscribe((data: GenericTableReportOfServiceDeploymentViewModel) => {
        this.cols = data.columnList.filter(c => c.field !== 'banner');
        this.data = data.results;
        this.totalRecords = data.totalCount;
        this.loading = false;
        this._dataPassingService.passColumns(this.cols);
      });
  }

  ngOnDestroy() {
    this.dataSubscription.unsubscribe();
    this.serviceDeploymentsReportSubscription && this.serviceDeploymentsReportSubscription.unsubscribe();
  }

  sortdata(event: LazyLoadEvent) {
    this.serviceDeploymentsReportSubscription = this.serviceDeploymentsReport$.pipe(take(1)).subscribe(filters => {
      this.serviceDeploymentFilterParams = filters;
      let order;
      if (event.sortField == undefined) event.sortField = '-serviceStart';

      let sortFieldwithOrdering = '';
      if (event.sortOrder === -1) {
        sortFieldwithOrdering = sortFieldwithOrdering.concat('-').concat(event.sortField);
        order = false;
      } else {
        sortFieldwithOrdering = sortFieldwithOrdering.concat(event.sortField);
        order = true;
      }

      const updateFilter = Object.assign(this.serviceDeploymentFilterParams, {
        sortColumn: sortFieldwithOrdering,
      });
      this._serviceDeploymentService.setFilter(updateFilter);
      this.pullData(updateFilter);
    });
  }

  searchbyId(serviceDeploymentId: string) {
    if (serviceDeploymentId !== undefined) {
      serviceDeploymentId = serviceDeploymentId.trim();
      const updateServiceDeploymnetFilter = Object.assign(
        this.serviceDeploymentFilterParams.servicedeploymentSearchParameters,
        { serviceDeploymentId: serviceDeploymentId },
        { page: 1 },
        { pageSize: 20 },
      );
      const updateFilter = Object.assign(this.serviceDeploymentFilterParams, {
        servicedeploymentSearchParameters: updateServiceDeploymnetFilter,
      });
      this._serviceDeploymentService.setFilter(updateFilter);
      this.pullData(updateFilter);
    }
  }

  public onDownload() {
    this.loading = true;
    this._serviceDeploymentService.export(this.filters).subscribe((file: FileResponse) => {
      const date = moment().utc().format('YYYY_MM_DD_HH_mm');
      const fileName = `ServiceDeployments_${date}_UTC.csv`;
      CommonUtils.download(file.data, fileName);
      this.loading = false;
    });
  }

  public addServiceDeployments() {
    this.currentServiceDeployment = new AddServiceDeployment();
    this.currentServiceDeployment.add = true;
    this.currentServiceDeployment.edit = false;
    this._addServiceDeploymentReduxService.setFilter(this.currentServiceDeployment);
    this._router.navigate(['/servicedeployments', 'addservicedeployments']);
  }

  public editServiceDeployment(data: AddServiceDeployment) {
    data.add = false;
    data.edit = true;
    this._addServiceDeploymentReduxService.setFilter(data);
    this._router.navigate(['/servicedeployments', 'addservicedeployments']);
  }

  onFiltersChanged(filters: ServiceDeploymentSearchParameters) {
    this.filters = filters;

    const updatedFilter = Object.assign(this.serviceDeploymentFilterParams, {
      servicedeploymentSearchParameters: filters,
    });

    this._serviceDeploymentService.setFilter(updatedFilter);
    this.pullData(updatedFilter);
  }
}
